@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.btnAddContract {
    display:inline-block;
    border:#666 1px solid;
    margin:2px;
    font-size:24px;
    cursor:pointer;
    width:50px;
    height:50px;
    border-radius:50%;
}
/*.btnAddContract:hover{
    color:#ffffff;
    width:200px;
}*/